import React, { Component } from "react";
import queryString from "query-string";

// app header
import AppHeader from "../components/AppHeader/AppHeader";

// seo
import Seo from "../components/Seo";

// hook
import { useBookPackQuery } from "../hooks/useBookPackQuery";
import { useWindowSize } from "../hooks/useWindowSize";

// constant
import constant from "../constants";

// utils
import { splitName, splitPlanCodeBookPack } from "../utils";

// services
import { sendToCT } from "../services/Clevertap";
import { sendToLSQ } from "../services/LeadSquared";

// email
import { sendEmailNotifecation } from "../services/SendEmail";

// componentts
import BookPackHeading from "../components/BookPack/BookPackHeading";
import BookPackPilotForm from "../components/BookPack/BookPackPilotForm";
import FooterTerms from "../components/Footer/FooterTerms";

// api actions
import {
  getClientDetails,
  handlePaymentOfBookPack,
} from "../actions/bookPackActions";

class BookPackPilotWebinar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      serverError: "",
      type: "DIRECT_M2_ANNUAL",
      planCode: "",
      planPrice: 0,
      coupon: "",
      couponApplied: false,
      couponStatus: "",
      clintInfo: {},
      annualPlanPrice: 0,
      selectedPlan: "annualPlan",
      productId: "",
      installmentPlanPrice: 0,
      firstInstallmentPrice: 0,
      quarterlyPlanPrice: 0,
      monthlyPlanPrice: 0,
      toggleButtonValue: "pilot_diabetes",
      medicalDisorderType: "DIABETES",
      annualOfferPrice: 0,
      installmentOfferPrice: 0,
      quarterlyOfferPrice: 0,
      monthlyOfferPrice: 0,
    };
  }

  // Function -> To fetch the client details
  getInitialData = async clientId => {
    try {
      // api call -> to get client details
      const response = await getClientDetails(clientId);
      this.setState({ clintInfo: response.results[0], fetching: true });
    } catch (error) {
      console.error("client api server error", error);
      this.setState({
        serverError: "Unable to fetch your details. Try again",
        fetching: true,
      });
    }
  };

  componentDidMount() {
    const annualPlan = this.props.bookPack.pilot_diabetes.annualPlan[0];
    const installmentPlan =
      this.props.bookPack.pilot_diabetes.installmentPlan[0];
    const quarterlyPlan = this.props.bookPack.pilot_diabetes.quarterlyPlan[0];
    const monthlyPlan = this.props.bookPack.pilot_diabetes.monthlyPlan[0];

    let plan = null;
    if (this.state.selectedPlan === "installmentPlan") {
      plan = installmentPlan;
    } else if (this.state.selectedPlan === "quarterlyPlan") {
      plan = quarterlyPlan;
    } else if (this.state.selectedPlan === "monthlyPlan") {
      plan = monthlyPlan;
    } else {
      plan = annualPlan;
    }

    const annualPlanData = splitPlanCodeBookPack(annualPlan);
    const installmentPlanData = splitPlanCodeBookPack(installmentPlan);
    const quarterlyPlanData = splitPlanCodeBookPack(quarterlyPlan);
    const monthlyPlanData = splitPlanCodeBookPack(monthlyPlan);

    plan &&
      this.setState({
        planCode: annualPlanData[0],
        planPrice: Number(annualPlanData[1]),
        planDay: plan.planDay,
        productId: plan.productId,
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentPlanData[1]),
        firstInstallmentPrice: Number(installmentPlanData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        annualOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.annualPlan[0].offerPrice
        ),
        installmentOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.installmentPlan[0].offerPrice
        ),
        quarterlyOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.quarterlyPlan[0].offerPrice
        ),
        monthlyOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.monthlyPlan[0].offerPrice
        ),
      });

    const { clientId } = queryString.parse(this.props.location.search);
    if (clientId) {
      this.getInitialData(clientId);
    }
  }

  // Function -> To handle the payment of book pack
  handlePayNow = async values => {
    const languageArr = [];
    if (values.language && values.language !== "") {
      values.language.map(item => {
        languageArr.push(item.name);
        return languageArr;
      });
    }

    // util function -> to split the name
    const { firstName, lastName } = splitName(values.name);

    // leadSquared params
    let leadSquaredParams = {
      FirstName: firstName,
      LastName: lastName,
      Phone: values.phone,
      EmailAddress: values.email,
      mx_Website_CTA: "book_pack_pilot_webinar",
      mx_Latest_Source: "New Website",
    };

    let subscriptionType = "";
    if (this.state.selectedPlan === "annualPlan") {
      subscriptionType = "YEARLY";
    } else if (this.state.selectedPlan === "installmentPlan") {
      subscriptionType = "HALF_YEARLY";
    } else if (this.state.selectedPlan === "quarterlyPlan") {
      subscriptionType = "QUARTERLY";
    } else if (this.state.selectedPlan === "monthlyPlan") {
      subscriptionType = "MONTHLY";
    }

    // payment v3
    let paymentBody = {
      firstName: firstName,
      lastName: lastName,
      mobileNumber: values.phone,
      email: values.email,
      planCode: this.state.planCode,
      preferredM2Plan: this.state.type,
      m1PlanCode: this.state.planCode,
      m2PlanCode: this.state.planCode,
      sourceType: "DIGITAL_ORGANIC_TW",
      couponCode: this.state.coupon ? this.state.coupon.toUpperCase() : "",
      languageList: languageArr,
      medicalDisorderType: this.state.medicalDisorderType,
      subscriptionType: subscriptionType,
      address: {
        postalCode: values.pinCode ? values.pinCode : "",
        city: values.city,
        line1: "",
      },
    };

    try {
      this.setState({ loading: true });

      // LSQ Call
      // await sendToLSQ(leadSquaredParams);
      // Zoho Payment Call
      // const response = await handlePaymentOfBookPack(paymentBody);

      const results = await Promise.allSettled([
        handlePaymentOfBookPack(paymentBody),
        sendToLSQ(leadSquaredParams),
      ]);

      // console.log("results", results);

      const response = results[0].value;

      const paymentUrl = response.results[0].url;
      // console.log("paymentUrl..", paymentUrl)

      this.setState({ loading: false });
      window.location.href = paymentUrl;

      // clevertap event
      sendToCT("Enrollment Initiated", {
        action: "BookPack_Buy_Now Clicked - webinar",
        name: values.name,
        phone: values.phone,
        email: values.email,
        pincode: values.pinCode,
        coupon: this.state.coupon,
        paymentPlan: this.state.planCode,
      });
    } catch (error) {
      // send an email
      sendEmailNotifecation(
        window.location.href,
        paymentBody,
        error || {},
        "/marketing/v3/prospect"
      );

      console.error("payment api server error", error);
      this.setState({
        loading: false,
        serverError: constant.BOOKPACK_ERROR_MESSAGES.serverError,
      });
    }
  };

  // This function executes when we apply coupon
  handleCouponUpdate = (planPrice, coupon, discountAmt) => {
    if (coupon) {
      this.setState({
        showConfetti: true,
        planPrice: Number(planPrice),
        coupon: coupon,
        couponApplied: true,
      });

      // update plan price of selected plan after coupon applied
      if (this.state.selectedPlan === "installmentPlan") {
        this.setState({
          installmentPlanPrice: Number(planPrice),
          firstInstallmentPrice:
            Number(this.state.firstInstallmentPrice) - Number(discountAmt),
        });
      } else if (this.state.selectedPlan === "quarterlyPlan") {
        this.setState({ quarterlyPlanPrice: Number(planPrice) });
      } else if (this.state.selectedPlan === "monthlyPlan") {
        this.setState({ monthlyPlanPrice: Number(planPrice) });
      } else {
        this.setState({ annualPlanPrice: Number(planPrice) });
      }

      setTimeout(
        function () {
          this.setState({ showConfetti: false });
        }.bind(this),
        5000
      );
    }
  };

  // This function executes when we remove the applied coupon
  removedCoupon = () => {
    const { selectedPlan } = this.state;
    const plan = this.props.bookPack[selectedPlan][0];
    const pincodeCategory = this.state.pincodeCategory;
    const data =
      this.props.bookPack[selectedPlan][0][pincodeCategory].split("#");

    // update state based on pincode category ie GOLD(serviceable) or SILVER(Remote)
    // when coupon is removed
    if (
      pincodeCategory === constant.BOOKPACK_CATEGORIES.TIER_I ||
      pincodeCategory === constant.BOOKPACK_CATEGORIES.TIER_II
    ) {
      // update plan price of selected plan after coupon removed
      if (selectedPlan === "installmentPlan") {
        this.setState({
          installmentPlanPrice: Number(data[1]),
          firstInstallmentPrice: Number(data[2]),
        });
      } else if (selectedPlan === "quarterlyPlan") {
        this.setState({ quarterlyPlanPrice: Number(data[1]) });
      } else if (selectedPlan === "monthlyPlan") {
        this.setState({ monthlyPlanPrice: Number(data[1]) });
      } else {
        this.setState({ annualPlanPrice: Number(data[1]) });
      }

      this.setState({
        planCode: data[0],
        planPrice: Number(data[1]),
        couponApplied: false,
        coupon: "",
      });
    } else {
      this.setState({
        planCode: plan.planCode,
        planPrice: Number(plan.planPrice),
        planDay: plan.planDay,
        type: plan.planType,
        couponApplied: false,
        coupon: "",
        annualPlanPrice: Number(plan.planPrice),
        installmentPlanPrice: Number(plan.planPrice),
        quarterlyPlanPrice: Number(plan.planPrice),
        monthlyPlanPrice: Number(plan.planPrice),
      });
    }
  };

  // Function -> Handle radio button plan cards
  handlePlan = val => {
    const annualPlanData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].annualPlan[0]
    );
    const installmentData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].installmentPlan[0]
    );

    const quarterlyPlanData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].quarterlyPlan[0]
    );

    const monthlyPlanData = splitPlanCodeBookPack(
      this.props.bookPack[this.state.toggleButtonValue].monthlyPlan[0]
    );

    let planCode = "";
    let planPrice = "";

    if (val === "installmentPlan") {
      planCode = installmentData[0];
      planPrice = Number(installmentData[1]);
    } else if (val === "quarterlyPlan") {
      planCode = quarterlyPlanData[0];
      planPrice = Number(quarterlyPlanData[1]);
    } else if (val === "monthlyPlan") {
      planCode = monthlyPlanData[0];
      planPrice = Number(monthlyPlanData[1]);
    } else {
      planCode = annualPlanData[0];
      planPrice = Number(annualPlanData[1]);
    }

    this.setState({
      selectedPlan: val,
      planCode: planCode,
      planPrice: planPrice,
      firstInstallmentPrice: Number(installmentData[2]),
    });
  };

  // Handle toggle button functionality
  handleToggleButton = value => {
    // Handle price & plancode for Diabetes toggle button
    if (value === "pilot_diabetes") {
      const annualPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_diabetes.annualPlan[0]
      );
      const installmentPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_diabetes.installmentPlan[0]
      );

      const quarterlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_diabetes.quarterlyPlan[0]
      );

      const monthlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_diabetes.monthlyPlan[0]
      );

      this.setState({
        planCode: annualPlanData[0],
        planPrice: Number(annualPlanData[1]),
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentPlanData[1]),
        firstInstallmentPrice: Number(installmentPlanData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        toggleButtonValue: value,
        selectedPlan: "annualPlan",
        medicalDisorderType: "DIABETES",
        annualOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.annualPlan[0].offerPrice
        ),
        installmentOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.installmentPlan[0].offerPrice
        ),
        quarterlyOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.quarterlyPlan[0].offerPrice
        ),
        monthlyOfferPrice: Number(
          this.props.bookPack.pilot_diabetes.monthlyPlan[0].offerPrice
        ),
      });
    } else if (value === "pilot_obesity") {
      // Handle price & plancode for Obesity toggle button
      const annualPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_obesity.annualPlan[0]
      );
      const installmentPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_obesity.installmentPlan[0]
      );

      const quarterlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_obesity.quarterlyPlan[0]
      );

      const monthlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_obesity.monthlyPlan[0]
      );

      this.setState({
        planCode: annualPlanData[0],
        planPrice: Number(annualPlanData[1]),
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentPlanData[1]),
        firstInstallmentPrice: Number(installmentPlanData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        toggleButtonValue: value,
        selectedPlan: "annualPlan",
        medicalDisorderType: "OBESITY",
        annualOfferPrice: Number(
          this.props.bookPack.pilot_obesity.annualPlan[0].offerPrice
        ),
        installmentOfferPrice: Number(
          this.props.bookPack.pilot_obesity.installmentPlan[0].offerPrice
        ),
        quarterlyOfferPrice: Number(
          this.props.bookPack.pilot_obesity.quarterlyPlan[0].offerPrice
        ),
        monthlyOfferPrice: Number(
          this.props.bookPack.pilot_obesity.monthlyPlan[0].offerPrice
        ),
      });
    } else if (value === "pilot_pre_diabetes") {
      // Handle price & plancode for Pre-Diabetes toggle button
      const annualPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_pre_diabetes.annualPlan[0]
      );
      const installmentPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_pre_diabetes.installmentPlan[0]
      );

      const quarterlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_pre_diabetes.quarterlyPlan[0]
      );

      const monthlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_pre_diabetes.monthlyPlan[0]
      );

      this.setState({
        planCode: annualPlanData[0],
        planPrice: Number(annualPlanData[1]),
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentPlanData[1]),
        firstInstallmentPrice: Number(installmentPlanData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        toggleButtonValue: value,
        selectedPlan: "annualPlan",
        medicalDisorderType: "PRE_DIABETES",
        annualOfferPrice: Number(
          this.props.bookPack.pilot_pre_diabetes.annualPlan[0].offerPrice
        ),
        installmentOfferPrice: Number(
          this.props.bookPack.pilot_pre_diabetes.installmentPlan[0].offerPrice
        ),
        quarterlyOfferPrice: Number(
          this.props.bookPack.pilot_pre_diabetes.quarterlyPlan[0].offerPrice
        ),
        monthlyOfferPrice: Number(
          this.props.bookPack.pilot_pre_diabetes.monthlyPlan[0].offerPrice
        ),
      });
    } else if (value === "pilot_metabolic_wellness") {
      // Handle price & plancode for Pre-Diabetes toggle button
      const annualPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_metabolic_wellness.annualPlan[0]
      );
      const installmentPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_metabolic_wellness.installmentPlan[0]
      );

      const quarterlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_metabolic_wellness.quarterlyPlan[0]
      );

      const monthlyPlanData = splitPlanCodeBookPack(
        this.props.bookPack.pilot_metabolic_wellness.monthlyPlan[0]
      );

      this.setState({
        planCode: annualPlanData[0],
        planPrice: Number(annualPlanData[1]),
        annualPlanPrice: Number(annualPlanData[1]),
        installmentPlanPrice: Number(installmentPlanData[1]),
        firstInstallmentPrice: Number(installmentPlanData[2]),
        quarterlyPlanPrice: Number(quarterlyPlanData[1]),
        monthlyPlanPrice: Number(monthlyPlanData[1]),
        toggleButtonValue: value,
        selectedPlan: "annualPlan",
        medicalDisorderType: "PRE_DIABETES",
        annualOfferPrice: Number(
          this.props.bookPack.pilot_metabolic_wellness.annualPlan[0].offerPrice
        ),
        installmentOfferPrice: Number(
          this.props.bookPack.pilot_metabolic_wellness.installmentPlan[0]
            .offerPrice
        ),
        quarterlyOfferPrice: Number(
          this.props.bookPack.pilot_metabolic_wellness.quarterlyPlan[0]
            .offerPrice
        ),
        monthlyOfferPrice: Number(
          this.props.bookPack.pilot_metabolic_wellness.monthlyPlan[0].offerPrice
        ),
      });
    }
  };

  render() {
    const {
      annualPlanPrice,
      clintInfo,
      loading,
      productId,
      planPrice,
      planDay,
      selectedPlan,
      serverError,
      installmentPlanPrice,
      firstInstallmentPrice,
      toggleButtonValue,
      quarterlyPlanPrice,
      monthlyPlanPrice,
      annualOfferPrice,
      installmentOfferPrice,
      quarterlyOfferPrice,
      monthlyOfferPrice,
    } = this.state;

    const { appHeader } = queryString.parse(this.props.location.search);

    return (
      <div className="bgLightgray" style={{ minHeight: "92vh" }}>
        <Seo
          title="Reverse Diabetes, Obesity, PCOS & More"
          description="AI-driven treatment to reverse Diabetes, Hypertension, Obesity & Other Chronic Conditions. 97% reduced blood sugar & meds, 98% eliminated symptoms. Start today."
        />

        {appHeader === "mobile" && <AppHeader text="BOOK PACK" isBack={true} />}

        {/* Heading */}
        <BookPackHeading />

        {/* Form */}
        <BookPackPilotForm
          price={planPrice}
          handlePayNow={this.handlePayNow}
          loading={loading}
          handleCouponUpdate={this.handleCouponUpdate}
          coupon={this.state.coupon}
          removedCoupon={this.removedCoupon}
          selectedPlan={selectedPlan}
          name={clintInfo.name}
          email={clintInfo.email}
          phone={clintInfo.phone}
          postalCode={clintInfo.postalCode}
          locationSearch={this.props.location.search}
          productId={productId}
          annualPlanPrice={annualPlanPrice}
          planDay={planDay}
          showCouponSection={false}
          windowSize={this.props.windowSize}
          couponType="bookpackCoupon"
          leadSquaredParam="book_pack_pilot_webinar"
          handlePlan={this.handlePlan}
          installmentPlanPrice={installmentPlanPrice}
          firstInstallmentPrice={firstInstallmentPrice}
          handleToggleButton={this.handleToggleButton}
          toggleButtonValue={toggleButtonValue}
          quarterlyPlanPrice={quarterlyPlanPrice}
          monthlyPlanPrice={monthlyPlanPrice}
          annualOfferPrice={annualOfferPrice}
          installmentOfferPrice={installmentOfferPrice}
          quarterlyOfferPrice={quarterlyOfferPrice}
          monthlyOfferPrice={monthlyOfferPrice}
          showAnnualCard={true}
          showInstallmentCard={false}
          showQuarterly={true}
          showMonthly={false}
        />
        <div className="text-center text-danger bgLightBlue">
          {serverError} &nbsp;
        </div>
        <div>
          <FooterTerms isHome={false} />
        </div>
      </div>
    );
  }
}

const WithContext = props => {
  const bookPackPayloadDiabetes = useBookPackQuery("webinar_diabetes");
  const bookPackPayloadObesity = useBookPackQuery("webinar_obesity");
  const bookPackPayloadPreDiabetes = useBookPackQuery("webinar_pre_diabetes");
  const bookPackPayloadMetabolicWellness = useBookPackQuery(
    "webinar_metabolic_wellness"
  );
  const windowSize = useWindowSize();

  const finalBookPackPayload = {
    pilot_diabetes: bookPackPayloadDiabetes,
    pilot_obesity: bookPackPayloadObesity,
    pilot_pre_diabetes: bookPackPayloadPreDiabetes,
    pilot_metabolic_wellness: bookPackPayloadMetabolicWellness,
  };

  return (
    <BookPackPilotWebinar
      {...props}
      bookPack={finalBookPackPayload}
      windowSize={windowSize}
    />
  );
};

export default WithContext;
